import '../styles/index.scss';
import 'bootstrap';
import './nav/video';
import './nav/links';
import './nav/dropdown';
import './mouse-indicator/mouse-indicator';
import './text-change/text-change';
import './carousel/carousel';
import './countup/countup';
import AOS from 'aos';
import './modal/modal';
import './form/form';
import './cookies/cookies';
AOS.init();