import axios from "axios";

const forms = document.querySelectorAll('form');
const handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');
    const url = e.target.getAttribute('action');
    const fields = e.target.querySelectorAll('input, textarea');
    const typSelector = e.target.getAttribute('data-typ');
    let values = {};
    fields.forEach(f => {
        f.parentNode.removeAttribute('data-error');
        if (f.getAttribute('type') === 'checkbox') {
            if (f.checked) {
                values[f.getAttribute('name')] = 1;
            } else {
                values[f.getAttribute('name')] = 0;
            }
        } else {
            values[f.getAttribute('name')] = f.value;
        }
    });

    axios.post(url, values)
        .then(response => {
            if (response.data.errors && Object.keys(response.data.errors).length) {
                for (const prop in response.data.errors) {
                    e.target.querySelector('[name="' + prop + '"]').parentNode.setAttribute('data-error', response.data.errors[prop]);
                }

                return 0;
            }
            if (response.data.exception) {
                alert(response.data.exception);

                return 0;
            }
            document.querySelector(typSelector).classList.add('active');
        })
        .catch(error => {
            console.log(error);
            alert('Wystąpił błąd. Proszę spróbować ponownie.');
        })
        .finally(() => {
            e.target.querySelector('[type="submit"]').removeAttribute('disabled');
        });
};

forms.forEach(f => {
    f.addEventListener('submit', handleFormSubmit);
});