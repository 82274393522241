import Cookies from 'js-cookie';

const cookie = Cookies.get('cookiesAccepted');
const messageEl = document.querySelector('.cookies-message');
const buttonEl = document.querySelector('#accept-cookies');
const buttonClickHandler = () => {
    Cookies.set('cookiesAccepted', 'yes', { expires: 365 });
    messageEl.classList.add('d-none');
};
if (cookie !== 'yes') {
    messageEl.classList.remove('d-none');
}
buttonEl.addEventListener('click', buttonClickHandler);