import Promise from "promise";

async function init() {
    const node = document.querySelector("#type-text");

    await sleep(1000);
    node.innerText = "";

    while (true) {
        await node.type('zarządzaj bazą pracowników');
        await sleep(2000);
        await node.delete('zarządzaj bazą pracowników');

        await node.type('sprawnie podpisuj dokumenty');
        await sleep(2000);
        await node.delete('sprawnie podpisuj dokumenty');

        await node.type('dynamicznie planuj grafik pracy');
        await sleep(2000);
        await node.delete('dynamicznie planuj grafik pracy');

        await node.type('weryfikuj obecność i czas pracy');
        await sleep(2000);
        await node.delete('weryfikuj obecność i czas pracy');

        await node.type('zbieraj wydatki pracowników');
        await sleep(2000);
        await node.delete('zbieraj wydatki pracowników');

        await node.type('wyznaczaj i kontroluj zadania');
        await sleep(2000);
        await node.delete('wyznaczaj i kontroluj zadania');

        await node.type('zarządzaj sprzętem lub towarem');
        await sleep(2000);
        await node.delete('zarządzaj sprzętem lub towarem');

        await node.type('monitoruj i rozliczaj koszty pracy');
        await sleep(2000);
        await node.delete('monitoruj i rozliczaj koszty pracy');
    }
}

const sleep = (time) => new Promise(resolve => setTimeout(resolve, time));

class TypeAsync extends HTMLSpanElement {
    get typeInterval() {
        const randomMs = 100 * Math.random();
        return randomMs < 50 ? 10 : randomMs;
    }

    async type(text) {
        for (let character of text) {
            this.innerText += character;
            await sleep(this.typeInterval);
        }
    }

    async delete(text) {
        for (const character of text) {
            this.innerText = this.innerText.slice(0, this.innerText.length - 1);
            await sleep(this.typeInterval);
        }
    }
}

customElements.define('type-async', TypeAsync, {extends: 'span'});

init();