const video = document.querySelector('#nav-vid');
const setVideoSrc = (video) => {
    const attr = getVideoSrc();
    if (attr !== video.getAttribute('src')) {
        video.setAttribute('src', attr);
    }
};
const getVideoSrc = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1200) {
        return '/public/mp4/1024.mp4';
    }
    if (windowWidth >= 480) {
        return '/public/mp4/480.mp4';
    }
    return '/public/mp4/240.mp4';
};
const ensureVideoPlays = (video) => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
window.addEventListener('resize', function () {
    setVideoSrc(video);
});
window.addEventListener('load', function () {
    setVideoSrc(video);
});
window.addEventListener('click', function () {
    if (!ensureVideoPlays(video)) {
        video.play();
    }
}, {once: true});
window.addEventListener('touchstart', function () {
    if (!ensureVideoPlays(video)) {
        video.play();
    }
}, {once: true});
