const navLinks = document.querySelectorAll('.navbar-nav .nav-item .nav-link');

const handleNavLinkClick = (event) => {
    const href = event.target.getAttribute('href');
    if (href.charAt(0) === '#') {
        event.preventDefault();
        const target = document.querySelector(href);
        const targetOffset = target.offsetTop;
        const headerHeight = document.querySelector('nav.navbar').clientHeight;
        const finalOffset = targetOffset - headerHeight;

        window.scrollTo({
           top: finalOffset,
           behavior: 'smooth',
        });
    }
};

navLinks.forEach(e => {
   e.addEventListener('click', handleNavLinkClick);
});