import $ from 'jquery';
import 'slick-carousel';

window.jQuery = window.$ = $;

$('.slick-problems').slick({
    mobileFirst: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    ]
});

$('.slick-target').slick({
    mobileFirst: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    arrows: false,
    dots: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    ]
});
