import { CountUp } from 'countup.js';

window.onload = () => {
    const els = document.querySelectorAll('.number');
    els.forEach(e => {
       new CountUp(e, e.getAttribute('data-end'), {
           enableScrollSpy: true,
           scrollSpyOnce: false,
       });
    });
};
