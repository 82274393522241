const dropdown = document.querySelector('.dropdown');

const handleDropdownClick = (event) => {
    const content = event.target.querySelector('.dropdown-content');
    if (!content) {
        return;
    }
    event.preventDefault();
    content.classList.contains('inactive') ?
        content.classList.remove('inactive') :
        content.classList.add('inactive');

    handleScrollAndClickOutside(content);
};

const handleScrollAndClickOutside = (content) => {
    if (!content.classList.contains('inactive')) {
        document.addEventListener('scroll', () => {
            content.classList.add('inactive');
        }, {once: true});
        content.addEventListener('mouseleave', () => {
            content.classList.add('inactive');
        }, {once: true});
    }
};

dropdown.addEventListener('click', handleDropdownClick);